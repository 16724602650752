@use '~@angular/material' as mat;
@import '~angular-mgl-timeline/theme';

@import '/src/app/core/components/account/account.theme.scss';
@import '/src/app/core/components/action-card/action-card.theme.scss';
@import '/src/app/core/components/resource-table/resource-table.theme.scss';
@import '/src/app/core/components/tree-view/tree-view.theme.scss';
@import '/src/app/core/components/workflow-design/workflow-design.theme.scss';
@import '/src/app/core/components/signin/signin.theme.scss';
@import '/src/app/core/components/report-card/report-card.theme.scss';
@import '/src/app/core/components/activities/activity-rest-api-call/activity-rest-api-call.theme.scss';

@import '/src/app/core/components/editor-date/editor-date.theme.scss';
@import '/src/app/core/components/editor-boolean/editor-boolean.theme.scss';
@import '/src/app/core/components/editor-identity/editor-identity.theme.scss';
@import '/src/app/core/components/editor-identities/editor-identities.theme.scss';
@import '/src/app/core/components/editor-select/editor-select.theme.scss';
@import '/src/app/core/components/editor-for-each/editor-for-each.theme.scss';

@include mat.core();

$custom-theme-primary: mat.define-palette(mat.$indigo-palette);
$custom-theme-accent: mat.define-palette(mat.$teal-palette);
$custom-theme-warn: mat.define-palette(mat.$red-palette);

$custom-theme-warn-bg: mat.define-palette(mat.$red-palette, 200, 200, 200);

$custom-theme: mat.define-light-theme(
  $custom-theme-primary,
  $custom-theme-accent,
  $custom-theme-warn
);

@mixin custom-theme($theme) {
  @include account-theme($theme);
  @include action-card-theme($theme);
  @include resource-table-theme($theme);
  @include tree-view-theme($theme);
  @include workflow-design-theme($theme);
  @include signin-theme($theme);
  @include report-card-theme($theme);
  @include activity-rest-api-call-theme($theme);

  @include editor-date-theme($theme);
  @include editor-boolean-theme($theme);
  @include editor-identity-theme($theme);
  @include editor-identities-theme($theme);
  @include editor-select-theme($theme);
  @include editor-for-each-theme($theme);
}

@include mat.all-component-themes($custom-theme);
@include mgl-timeline-theme($custom-theme);

@include custom-theme($custom-theme);

// general styles
.primary-color {
  color: mat.get-color-from-palette($custom-theme-primary) !important;
}
.accent-color {
  color: mat.get-color-from-palette($custom-theme-accent) !important;
}
.warn-color {
  color: mat.get-color-from-palette($custom-theme-warn) !important;
}

.primary-bg-color {
  background-color: mat.get-color-from-palette(
    $custom-theme-primary
  ) !important;
}
.accent-bg-color {
  background-color: mat.get-color-from-palette($custom-theme-accent) !important;
}
.warn-bg-color {
  background-color: mat.get-color-from-palette(
    $custom-theme-warn-bg
  ) !important;
}

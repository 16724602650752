@use '~@angular/material' as mat;

@mixin resource-table-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  app-resource-table .table-title {
    color: $text-color;
  }

  app-resource-table .clipboard-icon {
    color: $secondary-text-color;
  }

  app-resource-table .export-button {
    color: $secondary-text-color;
  }

  app-resource-table .virtual-pager {
    color: $secondary-text-color;
  }
}

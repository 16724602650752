@use '~@angular/material' as mat;

@mixin editor-identity-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  .k-textbox-container.k-state-focused > .k-label {
    color: mat.get-color-from-palette($primary) !important;
  }

  .k-textbox-container > .k-label {
    color: $secondary-text-color !important;
  }

  .k-textbox-container.k-state-focused::after {
    background-color: mat.get-color-from-palette($primary) !important;
  }

  .k-multiselect {
    background-color: transparent;
  }

  .k-multiselect .k-multiselect-wrap .k-searchbar .k-input {
    color: $text-color;
    font-weight: 400;
  }

  .k-list .k-item {
    color: $text-color;
  }
  .k-list .k-item:hover {
    color: $text-color;
  }

  .k-list .k-item.k-state-selected {
    color: mat.get-color-from-palette($warn) !important;
  }
  .k-list .k-item.k-state-selected:hover {
    color: mat.get-color-from-palette($warn) !important;
  }

  .k-popup .info-avatar .info-avatar-initial {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($primary);
  }

  .k-popup .popup-header {
    color: $text-color;
  }

  .k-multiselect .k-multiselect-wrap .k-button {
    color: $text-color;
  }

  // disabled state
  .k-textbox-container.idp-disabled.k-state-focused>.k-label {
    color: rgb(0, 0, 0, 0.54) !important;
  }
  .k-textbox-container.idp-disabled.k-state-focused::after {
    display: none;
  }
  .idp-disabled .k-multiselect .k-multiselect-wrap {
    border-bottom-style: dashed;
    border-bottom-color: lightgray;
  }
  .idp-disabled .k-multiselect .k-multiselect-wrap ul {
    opacity: 0.5;
  }
  .idp-disabled .k-multiselect .k-multiselect-wrap ul .k-icon.k-i-close {
    display: none;
  }

  app-editor-identity .label-attribute-desc {
    color: $secondary-text-color;
  }

  app-editor-identity .identity-search {
    color: $secondary-text-color;
  }

  app-editor-identity .identity-paste {
    color: $secondary-text-color;
  }
}

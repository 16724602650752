@use '~@angular/material' as mat;

@mixin workflow-design-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .activity-container {
    border: solid mat.get-color-from-palette($accent, 100) 5px;
  }

  .activity-container-nagative {
    border-radius: 5px;
    border: solid mat.get-color-from-palette($warn, 100) 5px;
  }

  .condition-start {
    background-color: mat.get-color-from-palette($accent, 100);
  }

  .condition-branch {
    border-top: mat.get-color-from-palette($accent, 100) solid 5px;
    border-left: mat.get-color-from-palette($accent, 100) solid 5px;
    border-right: mat.get-color-from-palette($accent, 100) solid 5px;
  }
}

@use '~@angular/material' as mat;

@mixin activity-rest-api-call-theme($theme) {
  $accent: map-get($theme, accent);

  .api-setting {
    border-bottom-color: mat.get-color-from-palette($accent) !important;
  }

  .api-setting-base {
    background-color: mat.get-color-from-palette($accent, 50) !important;
  }
}

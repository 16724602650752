@use '~@angular/material' as mat;

@mixin account-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .inner-brand {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .profile-avatar-initial {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($accent);
  }

  .profile-name {
    color: mat.get-color-from-palette($accent);
  }
}

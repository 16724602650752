@use '~@angular/material' as mat;

@mixin editor-date-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $text-color: mat.get-color-from-palette($foreground, text);
  $secondary-text-color: mat.get-color-from-palette($foreground, secondary-text);

  .label-attribute-focused {
    color: mat.get-color-from-palette($primary) !important;
  }

  .k-picker-wrap.k-state-focused {
    border-color: mat.get-color-from-palette($primary) !important;
  }

  .k-picker-wrap .k-input {
    color: $text-color !important;
  }

  .ng-invalid > div > .label-attribute-name {
    color: mat.get-color-from-palette($warn) !important;
  }

  .ng-invalid > div > .label-attribute-desc {
    color: mat.get-color-from-palette($warn) !important;
  }

  .label-attribute-name {
    color: $secondary-text-color;
  }

  .label-attribute-desc {
    color: $secondary-text-color;
  }

  .k-calendar .k-state-selected.k-state-focused .k-link {
    border-color: mat.get-color-from-palette($primary);
    background-color: mat.get-color-from-palette($primary);
    color: mat.get-color-from-palette($primary, default-contrast);
  }

  .k-calendar .k-content .k-today {
    color: mat.get-color-from-palette($primary);
  }

  .k-calendar .k-footer .k-nav-today,
  .k-calendar .k-calendar-header .k-today {
    color: mat.get-color-from-palette($accent);
  }

  .k-picker-wrap .k-icon.k-i-calendar {
    color: $secondary-text-color;
  }
}

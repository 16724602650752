@use '~@angular/material' as mat;

@mixin tree-view-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .k-treeview .k-in {
    color: mat.get-color-from-palette($foreground, text, 0.6);

    &:hover {
      background-color: mat.get-color-from-palette($background, hover);
    }
  }
  .k-treeview .k-in.k-state-selected {
    color: mat.get-color-from-palette($primary, default-contrast);
    background-color: mat.get-color-from-palette($primary);

    &:hover {
      background-color: mat.get-color-from-palette($primary);
    }
  }
}
